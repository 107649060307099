$background: #f2f3f9;
$default-color:#25252a;

/*Color variables*/
$primary-1:#5e2dd8; 
$primary-2:#d52b46; 
$primary-3:#569834; 
$primary-4:#4453fb; 
$primary-5:#f3532f; 
$secondary:#d43f8d;
$pink:#fc5296;
$teal:#1caf9f;
$purple:#8927ec;
$success:#09ad95;
$warning:#f7b731;
$danger:#f82649;
$info:#0774f8;
$orange:#fc7303;
$red:#e73827;
$lime:#7bd235;
$dark:#343a40;
$indigo:#6574cd;
$cyan:#007ea7;
$azure:#45aaf2;
$white:#fff;
$black:#000;
$light:#f2f2f9;
$gray:#5a6970;
$green:#4ecc48;
$blue:#3223f1;
$yellow:#FBB034;


/*Gradient variables*/
$primary-gradient-1:linear-gradient(to right, #5e2dd8  0%, #6d5df1 100%); 
$primary-gradient-2:linear-gradient(to right, #d52b46 0%, #bf4959 100%); 
$primary-gradient-3:linear-gradient(to right, #569834 0%, #627955 100%); 
$primary-gradient-4:linear-gradient(to right, #4453fb 0%, #222a88 100%); 
$primary-gradient-5:linear-gradient(to right, #f3532f 0%, #8a6f58 100%); 
$secondary-gradient:linear-gradient(to bottom right, #d43f8d 0% , #af4666 100%);
$warning-gradient:linear-gradient(to bottom right,#f66b4e 0%, #fbc434 100%);
$info-gradient:linear-gradient(to bottom right, #1e63c3 0%, #00f2fe 100%);
$danger-gradient:linear-gradient(to bottom right, #FF416C 0%, #FF4B2B 100%);
$success-gradient:linear-gradient(to bottom right, #21a544 0% , #62fb62 100%);

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4:rgba(255, 255, 255, 0.4);
$white-5:rgba(255, 255, 255, 0.5);
$white-6:rgba(255, 255, 255, 0.6);
$white-7:rgba(255, 255, 255, 0.7);
$white-8:rgba(255, 255, 255, 0.8);
$white-9:rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/*shadow variables*/
$shadow:0 5px 15px 5px rgba(80, 102, 224, 0.08);

$dark-theme:#1e2448;
$dark-theme2:#16192f;
$dark-theme3:#181d3e;


